::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

* {
  cursor: url("res/HANDS-CURSOR-2.cur"), auto !important;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.noMargin {
  margin: 0 !important;
}

.smaller {
  font-size: smaller;
}

.relative {
  position: relative;
}

html,
body {
  font-family: sans-serif;
  height: 100vh;
  margin: 0;
  background: linear-gradient(#f6dfff, #eec8ff);
  user-select: none;
}

.hoverScale {
  transition: 0.2s;
}

.hoverScale:hover {
  transform: scale(1.1);
}

.phone-container {
  margin: auto;
  width: 480px;
  height: 900px;
  background-color: black;
  background: linear-gradient(20deg, #333, black);
  border-radius: 40px;
  position: relative;
  transform-origin: top center;
  box-shadow: 0px 0px 40px #444;
}

.phone-screen {
  position: absolute;
  left: 10px;
  top: 10px;
  bottom: 10px;
  right: 10px;
  background-color: white;
  border-radius: 30px;
  overflow: hidden;
}

.phone-display {
  position: relative;
  height: 100%;
  background-color: white;
  background: radial-gradient(#eff6ee, #a3d1a7);
}

.phone-top {
  background-color: black;
  position: absolute;
  top: 10px;
  left: 100px;
  right: 100px;
  height: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  z-index: 1;
}

.phone-bar {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.phone-time {
  font-weight: bold;
}

.phone-infos {
  margin-left: auto;
}

.phone-content {
  height: 840px;
  overflow: auto;
}

.phone-nav {
  padding-top: 10px;
  display: flex;
  font-size: 2em;
  justify-content: space-around;
  align-items: flex-end;
}

.modalTransparent .ant-modal-content {
  background: transparent !important;
}
